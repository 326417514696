/* Page active */

.pagination .active a {
    vertical-align: sub !important;
}
.pagination .active:hover {
    color: white !important;
}
.pagination .active {
    color: #fff !important;
    background-color: #37ae8f !important;
    border-radius: 10px;
    height: 50px !important;
}

/* *** */

/* Les autres */
.pagination li:hover {
    color: #37ae8f !important;
}

.pagination li {
    background-color: white;
    margin-top:0px;
}

/* La page 1 */
.pagination li:nth-child(2){
    border-radius: 10px 0px 0px 10px; 
}

/* la dernière page */
.pagination li:nth-last-child(2){
    border-radius: 0px 10px 10px 0px; 
}

/* Bouton retour */
.pagination > .previous {
    background-color: white !important;
    border-radius: 99px;
    padding: 0;
    margin-right: 10px;
}

.pagination > .previous:hover {
    background-color: #37ae8f !important;
    color: white !important;
}
/* *** */

/* Bouton suivant */
.pagination > .next {
    background-color: white !important;
    border-radius: 99px;
    padding: 0;
    margin-left: 10px
}

.pagination > .next:hover {
    background-color: #37ae8f !important;
    color: white !important;
}
/* *** */

ul.hide{
    display: none !important;
    border: 1px solid black;
}
