input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(187, 187, 187);
  opacity: 1; /* Firefox */
}

input :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(187, 187, 187);
}

input ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgb(187, 187, 187);
}

input .border-input {
  border: 2px solid #e2e4e7 !important;
}
